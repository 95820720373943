import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from "../components/seo"
import BookingForm from "../components/BookingForm"
import RoomsList from '../components/RoomsList'
import PageBanner from '../components/PageBanner'
import banner_image from '../images/page-banners/rooms.jpg'
import MainFacilities from '../components/MainFacilities'
import ChildrenNotes from '../components/ChildrenNotes'

const RoomsPage =({data}) => {

  const {rooms} = data

  return (

    <Layout sidebarComponent={<BookingForm colWidth="col-md-12" productType="Luxury room"/>}>
      
      <SEO title="Luxury rooms" />

      <PageBanner image={banner_image} title="Our Luxury rooms"/>

      <MainFacilities />

      <div className="inner-content">
        <div className="row">
          <div className="col-lg-12">
            <RoomsList rooms={rooms.edges}/>
          </div>
          <div className="col-lg-12">
            <ChildrenNotes />
          </div>
        </div>
      </div>

    </Layout>
    
  )
}

export default RoomsPage

export const roomsPageQuery = graphql`
  query roomsPageQuery {
    rooms:
      allMarkdownRemark (
        filter: {
          frontmatter: {type: {eq: "Luxury room"}}
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              slogan
              type
              excerpt
              price
              path
              images {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
  }
`